import { useEffect, useState } from 'react';
import UserMenu from '../UserMenu';
import { Typography, useAuth, Avatar } from '@aiops/styleguide';
import './UserProfile.scss';
import {
  getUserPhotoAsBase64String,
  getPngBlobFromBase64String,
} from '../../shared/apiHelper';

/**
 * Type for props of UserProfile component.
 */
type UserProfileProps = {
  onSignOut: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

// String to render insteady of user's name if no name can be pulled from user
// object.
const EMPTY_NAME_STRING = "My Profile";

const UserProfile = ({ onSignOut }: UserProfileProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fullName, setFullName] = useState<string>("");
  const [imageSource, setImageSource] = useState<string>("");

  // If initials are set to a non-empty string, the initials will be rendered in
  // the user's avatar. If the initials are null, the avatar will fall back to 
  // a generic user icon (but it won't fall back for an empty string).
  const [initials, setInitials] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const { userIsSignedIn, getUser } = useAuth();

  /**
   * Fetches the user's photo from the PHOTO_ENDPOINT_KEY endpoint, creates a
   * blob from the encoded string, and sets the image source to the blob URL.
   */
  const getAndSetPhoto = async () => {
    try {
      const encodedString = await getUserPhotoAsBase64String();
      if (encodedString === "") {
        setImageSource("");
        return;
      }
      const imgBlob = getPngBlobFromBase64String(encodedString);
      setImageSource(URL.createObjectURL(imgBlob));
    } catch (err) {
      console.error("error fetching avatar img: ", err);
      setImageSource("");
    }
  }

  /**
   * Gets the user's full name and first name, and sets the initials to the
   * first letter of the first name.
   */
  const getUserAndSetInitials = async () => {
    getUser().then((user) => {
      setFullName(user?.fullName || "");
      const first = user?.firstName || "";
      setInitials(first ? first[0] : null);
    })
  }

  /**
   * Handle the user's avatar by getting the user's name and if possible photo.
   */
  useEffect(() => {
    getUserAndSetInitials();
    getAndSetPhoto();
  }, [userIsSignedIn])

  return (
    <>
      <UserMenu
        handleClose={handleClose}
        anchorEl={anchorEl}
        onSignOut={onSignOut}
        name={fullName || EMPTY_NAME_STRING}
      />
      <button
        className="clickable row profile-button"
        onClick={(e) => handleClick(e)}
        data-testid="user-profile-btn"
      >
        <Typography variant="paragraph1-bold" sx={{ margin: '0px 20px' }}>
          {fullName || EMPTY_NAME_STRING}
        </Typography>
        <Avatar
          alt={fullName}
          src={imageSource || null}
        >
          {initials || null}
        </Avatar>
      </button>
    </>
  );
};

export default UserProfile;
