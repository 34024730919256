import * as React from "react";
import "./SignOutButton.scss";
import { Typography, MenuItem } from "@aiops/styleguide";
import { signOut } from "@aiops/auth-util";

/**
 *  Renders a sign out button.
 */
const SignOutButton = () => {
  return (
    <MenuItem
      className="sign-out-button"
      onClick={signOut}
      data-testid="sign-out-button"
    >
      <Typography align="center" className="sign-out">
        Sign Out
      </Typography>
    </MenuItem>
  );
};

export default SignOutButton;
