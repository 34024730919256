import { useState } from 'react';
import { SettingsOutlined, StyledIconButton, useAuth } from '@aiops/styleguide';
import './SettingsButton.scss';
import SettingsMenu from '../SettingsMenu';

const SettingsButton = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const appsWithSettings = useAuth().permissions?.visibleAppsWithSettings || [];

  return (
    <div className="settings-button">
      <>
        <SettingsMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          apps={appsWithSettings}
        />
        <StyledIconButton
          onClick={handleClick}
          icon={<SettingsOutlined fontSize="medium" key="settings-icon" />}
          data-testid="settings-btn"
        />
      </>
    </div>
  );
};

export default SettingsButton;
