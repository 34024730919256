import aiopsLogoImg from '../../assets/aiopsLogo.svg';
import { navigateToUrl } from '../../utils';
import './LogoButton.scss';
import { IconButton } from '@aiops/styleguide';

const LogoButton = () => {

  return (
    <IconButton
      className="logo-link"
      onClick={() => navigateToUrl('/')}
      data-testid="logo-btn"
      disableRipple
    >
      <img className="logo-img" src={aiopsLogoImg} />
    </IconButton>
  );
};

export default LogoButton;
