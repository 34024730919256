import { useAuth } from '@aiops/styleguide';
import { navigateToUrl } from 'single-spa';
import LogoButton from '../LogoButton';
import ProtectedHeaderContent from '../ProtectedHeaderContent';
import './HeaderContent.scss';

const HeaderContent = () => {
  const { signOut, userIsSignedIn } = useAuth();

  const onSignOut = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    signOut().then(() => {
      navigateToUrl('/sign-in');
    });
  };

  return (
    <nav className="nav row">
      <LogoButton />
      <ProtectedHeaderContent
        userIsSignedIn={userIsSignedIn}
        onSignOut={onSignOut}
      />
    </nav>
  );
};

export default HeaderContent;
