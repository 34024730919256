import {
  CollapsibleMenu,
  Divider,
  ListItem,
  MenuItem,
  Typography,
} from '@aiops/styleguide';
import { navigateToUrl, constants } from '../../utils';

type AppObj = {
  appName: string,
  path: string,
}

type AppPickerMenuContentProps = {
  handleClose: (e?: any) => void,
  apps: AppObj[],
}

export const AppPickerMenuContent = ({ handleClose, apps }: AppPickerMenuContentProps) => {

  const navigateAndClose = (url: string) => {
    handleClose();
    navigateToUrl(url);
  }

  return (
    <>
      <ListItem>
        <Typography
          variant="subtitle1"
          className="clickable"
          onClick={() => navigateAndClose('/')}
          data-testid="ceh-button"
        >
          Client Experience Hub
        </Typography>
      </ListItem>
      <Divider />
      {apps.map((app) => {
        return (
          <MenuItem 
            onClick={() => navigateAndClose(app.path)}
            key={app.appName}
            data-testid={app.appName}>
              <Typography variant="paragraph1" align="center">
                {app.appName}
              </Typography>
          </MenuItem>
        );
      })}
    </>
  );
};

/**
 * Type for props of AppPickerMenu component.
 */
type AppPickerMenuProps = {
  anchorEl: Element | null;
  handleClose: (event: React.MouseEvent<Element, MouseEvent>) => void;
  apps: AppObj[];
};

const AppPickerMenu = ({ anchorEl, handleClose, apps }: AppPickerMenuProps) => {
  return (
    <CollapsibleMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      MenuContent={<AppPickerMenuContent handleClose={handleClose} apps={apps} />}
      width={constants.DROP_DOWN_MENU_WIDTH}
    />
  );
};

export default AppPickerMenu;
