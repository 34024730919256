import { getIdToken } from "@aiops/auth-util";
import { getFullEndpoint } from "../../utils";

export const getUserPhotoAsBase64String = async () => {
    const url = getFullEndpoint("PHOTO");
    return fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${getIdToken()}`,
        },
    }).then((response) => {
        if (response.ok) {
            return response.text();
        } else {
            throw new Error("Error fetching photo");
        }
    }).then((encodedString) => {
        if (encodedString === "") {
            throw new Error("encoded image is empty");
        }
        return encodedString;
    }).catch((error) => {
        console.error("error fetching photo: ", error);
        return "";
    })
}

export const getPngBlobFromBase64String = (str: string) => {
    return new Blob(
        [Uint8Array.from(window.atob(str), (c) => c.charCodeAt(0))],
        { type: 'image/png' }
    );
}