import SettingsButton from '../SettingsButton';
import UserProfile from '../UserProfile';
import AppPickerButton from '../AppPickerButton';
import './ProtectedHeaderContent.scss';

/**
 * Type for props of ProtectedHeaderContent component.
 */
type ProtectedHeaderContentProps = {
  userIsSignedIn: boolean;
  onSignOut: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

const ProtectedHeaderContent = ({
  userIsSignedIn,
  onSignOut,
}: ProtectedHeaderContentProps) => {

  return (
    <div className="row">
      {userIsSignedIn &&
        <>
          <SettingsButton />
          <AppPickerButton />
          <UserProfile onSignOut={onSignOut} />
        </>
      }
    </div>
  );
};

export default ProtectedHeaderContent;
