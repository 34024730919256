import * as React from 'react';
import {
  SettingsOutlined,
  ContactPageOutlined,
  ContactSupportOutlined,
  CollapsibleMenu,
  Divider,
  Typography,
  ListItem,
  MenuItem,
  Grid,
} from '@aiops/styleguide';

import './UserMenu.scss';
import SignOutButton from '../SignOutButton';

/**
 * Type for props of UserMenu component.
 */
type UserMenuProps = {
  anchorEl: Element | null;
  handleClose: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onSignOut: (event: React.MouseEvent<Element, MouseEvent>) => void;
  name: string;
};

export const UserMenuContent = ({ onSignOut, name }) => {
  return (
    <>
      <ListItem tabIndex={1}>
        <Grid container direction="column">
          <Typography variant="heading7">{name}</Typography>
        </Grid>
      </ListItem>
      <Divider />
      <SignOutButton />
    </>
  );
};

const UserMenu = ({ anchorEl, handleClose, onSignOut, name }: UserMenuProps) => {
  const open = Boolean(anchorEl);
  return (
    <CollapsibleMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      MenuContent={<UserMenuContent onSignOut={onSignOut} name={name} />}
    />
  );
};

export default UserMenu;
