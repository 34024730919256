import { Apps, StyledIconButton, useAuth } from '@aiops/styleguide';
import { useCallback, useState } from 'react';
import AppPickerMenu from '../AppPickerMenu';
import { useEffect } from 'react';

const AppPickerButton = () => {
  const { permissions } = useAuth();
  const { visibleApps } = permissions || { visibleApps: [] };

  // Button should only be visible if the user has access to more than one app
  // AND the user is not on the app picker page (ie at the path "/").
  const buttonIsVisible = visibleApps.length > 1
    && window.location.pathname
      .split('/')
      .filter((s) => s && s !== '/')
      .length > 0;

  // Dummy state component that only exists to force component rerender
  const [, updateState] = useState<any>();

  // Forces the component to rerender
  const forceRerender = useCallback(() => updateState({}), []);

  // Force rerender the component every time the window's popstate event fires
  // (it fires whenever the window's history changes).
  //
  // The header/component don't automatically re-render when the path changes.
  // This is on purpose, so that the microapps' state doesn't empty and have to
  // be rebuilt on all navigation (especially auth state). Listening to changes
  // in the window's location, and then rerendering accordingly, seems like the
  // only way to conditionally render based on location that doesn't couple the
  // header app to the microapps (for example by saving state in the header app
  // that each microapp has to update when it loads/unloads).
  useEffect(() => {
    window.addEventListener('popstate', forceRerender);
    return () => window.removeEventListener('popstate', forceRerender);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    buttonIsVisible
      ? <>
        <AppPickerMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          apps={visibleApps}
        />
        <StyledIconButton
          onClick={handleClick}
          icon={<Apps fontSize="small" key="app-picker-btn" />}
          data-testid="app-picker-btn"
        />
      </>
      : null
  );
};

export default AppPickerButton;
