import { AppWrapper, AuthProvider } from '@aiops/styleguide';
import HeaderContent from './components/HeaderContent';

export default function Root() {
  return (
    <AppWrapper fullWindowHeight={false}>
      <AuthProvider requireAuth={false}>
        <HeaderContent />
      </AuthProvider>
    </AppWrapper>
  );
}
