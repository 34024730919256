import {
  CollapsibleMenu,
  Divider,
  ListItem,
  MenuItem,
  Typography,
  useAuth,
} from '@aiops/styleguide';
import { navigateToUrl, constants } from '../../utils';
import "./SettingsMenu.scss";

export const SettingsMenuContent = ({ handleClose, apps }) => {

  // TODO: remove the hard-coding of the app id and alternative path
  const platformSettingsPath = useAuth().permissions?.apps['SETTINGS']?.path || 'settings';

  const navigateAndClose = (path) => {
    handleClose();
    navigateToUrl(path);
  }

  const getAppLink = (app) => {
    const absoluteSettingsPath = `/${app.path}/${app.settingsPath}`
    return (
      <MenuItem
        onClick={() => navigateAndClose(absoluteSettingsPath)}
        key={app.appId}
      >
        <Typography variant="paragraph1" align="center">
          {app.appName}
        </Typography>
      </MenuItem>
    )
  }

  return (
    <>
      <ListItem>
        <Typography
          variant="subtitle1"
          data-testid="settings-button"
        >
          AIOPS.D Settings
        </Typography>
      </ListItem >
      {apps.length > 0 && <Divider />}
      <MenuItem onClick={() => navigateAndClose(`/${platformSettingsPath}`)}>
        <Typography variant="paragraph1" align="center">
          AIOPS.D Admin
        </Typography>
      </MenuItem>
      {apps.map((app) => getAppLink(app))}
    </>
  );
}

/**
 * Type for props of SettingsMenu component.
 */
export type SettingsMenuProps = {
  anchorEl: Element | null;
  handleClose: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  apps: any[];
};

/**
 *
 */
const SettingsMenu = ({
  anchorEl,
  handleClose,
  apps,
}: SettingsMenuProps) => {

  return (
    <CollapsibleMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      MenuContent={<SettingsMenuContent handleClose={handleClose} apps={apps} />}
      width={constants.DROP_DOWN_MENU_WIDTH}
    />
  );
};

export default SettingsMenu;
