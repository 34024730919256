import { navigateToUrl as singleSpaNav } from "single-spa";
import { getConfig } from "@aiops/root-config";

/**
 * Navigates to the route passed as a parameter without reloading the entire
 * app (which would clear all saved state, including auth state). 
 * 
 * @param route 
 * The route to navigate to.
 * 
 */
export const navigateToUrl = (route: string) => {
  // This looks hacky and weird. Here's why:
  // 
  // We need to use single spa's navigateToUrl method (which uses the window's
  // history's pushState method under the hood) for navigation because using
  // window.location.replace, or just <a> tags with href links, will reload the
  // entire site, wiping all auth state, forwarding the user away to
  // re-authenticate. 
  // 
  // (This can also create an infinite loop: the user is redirected where they
  // want to go, which wipes all auth state, which pushes them to the external
  // url to re-authenticate, which then pushes them back to the app, which tries
  // again to redirect them where they want to go, wiping all auth state...)
  // 
  // The problem is that single spa's navigateToUrl function doesn't ALWAYS seem 
  // to work. Sometimes it replaces the path in the window without actually 
  // changing the content on the page. But it DOES seem to reliably work when
  // redirecting to or from the home ('/') path.
  // 
  // (For now this also seems to be a problem only in the header app. The other
  // microapps are able to use single spa's navigateToUrl function without
  // issue.)
  //
  // So by first pushing the user to the home path, and then pushing them
  // wherever we actually want them to go, we're able to push them anywhere
  // without completely reloading the site and dropping all auth state.
  // 
  // It's not totally clear why this is the case, and maybe we'll be able to
  // find a more elegant solution, but as a hacky workaround for now, this does
  // seem to work.
  singleSpaNav('/');
  singleSpaNav(route);
}

export const constants = {
  DROP_DOWN_MENU_WIDTH: 300,
  // Endpoints that can be combined with a base url of the form:
  // https://<random string>.execute-api.<region>.amazonaws.com/<stage>/
  // to create a full endpoint url. None of these should start with slashes.
  endpoints: {
    PHOTO: 'aiopsd/security/photo',
  },
}

/**
 * Returns the base url from the root config's getConfig.
 */
export const getBaseEndpoint = () => {
  return getConfig()?.endpointBaseUrl;
}

/**
 * Returns a full, callable endpoint url for the endpoint key passed as an
 * argument, which must be a valid key in the endpoints object at 
 * constants.endpoints
 * 
 * @param endpointKey 
 * The key of an endpoint in constants.endpoints, for example: "PHOTO".
 * 
 */
export const getFullEndpoint = (endpointKey: string) => {
  // Get base url or throw error if not found.
  const baseUrl = getConfig()?.endpointBaseUrl || "";
  if (!baseUrl) {
    throw new Error(`Base url not found in config: ${JSON.stringify(getConfig())}}`);
  }

  // Get endpoint from given key or throw error if not found.
  const endpoint = constants.endpoints[endpointKey];
  if (!endpoint) {
    throw new Error(`Endpoint key '${endpointKey}' not found among endpoints: ${Object.keys(constants.endpoints)}`);
  }

  // Check whether the base url ends with a slash, and if not, add one.
  return "https://btp-backend.dev.aiops-d.cloud/security/photo"
  // return `${baseUrl}${baseUrl[baseUrl.length - 1] === '/' ? '' : '/'}${endpoint}`;
}
